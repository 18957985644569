<template>
  <v-btn
    v-if="!isFilterEmpty"
    @click="clearFilters"
    class="mx-1"
    style="min-width: 0"
    variant="elevated"
  >
    <v-icon color="white">mdi-filter-remove</v-icon>
  </v-btn>
</template>

<script setup>
import { computed } from "vue";

const emptyStates = ["", null, undefined, [], {}, [0, 100], "all"].map(
  (value) => JSON.stringify(value)
);

const props = defineProps({
  filter: {
    type: Object,
    default: () => ({}),
  },
  clearFunction: {
    type: Function,
    default: null,
  },
});

const isFilterEmpty = computed(() => {
  return Object.values(props.filter).every((value) =>
    emptyStates.includes(JSON.stringify(value))
  );
});

const clearFilters = () => {
  if (props.clearFunction) {
    props.clearFunction();
    return;
  }

  Object.keys(props.filter).forEach((key) => {
    props.filter[key] = null;
  });
};
</script>
