<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
.apexcharts-menu {
  background: #22716e !important;
}

html {
  overflow-y: auto !important;
}

th {
  border-bottom: 1px solid #364043 !important;
  color: #e2b842 !important;
  font-size: 0.85em !important;
  font-weight: 600 !important;
  text-align: left !important;
}

.v-data-table thead {
  font-size: 1rem !important;
  color: white;
}

.v-data-table {
  font-size: 0.85rem !important;
}

.v-autocomplete__mask {
  background-color: #d65008 !important;
}
.emptyChartHeight {
  height: 400px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
